import React from 'react'
import { dataProgram } from '../Data/DataProgram';
import './Model_3.css';
import downArrow from '../../assets/images/down-arrow.svg';
import Fade from 'react-reveal/Fade';

function Model_3() {
  return (
    <div className='Container'>
      <div className="modelHero3" >
        <Fade bottom>
          <div className="itemText">
            <h1> Model 3 </h1>
            <p> Order Online For Touchless Delivery </p>
          </div>
        </Fade>
        <div className="buttonGroup">
          <Fade bottom>
            <div className='buttoms'>
              <button className="LeftButton"> Custom Order </button>
              <button className="LeftButton"> Existing Inventory </button>
            </div>
          </Fade>
          <div className='downArrow'>
            <img src={downArrow} alt='' />
          </div>
        </div>
      </div>

      <section className='sectionBox'>
        <div className='containerCustom'>
          {dataProgram.map((dataProgram) => (
            <div className='carDetailBox'>
            <Fade left>
              <div className='carDetail'>
                <h2><span>{dataProgram.title}</span></h2>
                <p><span>{dataProgram.detail}</span></p>
                <button className="learnButton">  {dataProgram.button} </button>
              </div>
              </Fade>
              <Fade right>
              <div className='carImage'>
                <img src={dataProgram.image} alt='' />
              </div>
              </Fade>
            </div>
          ))}
        </div>
      </section>
      
    </div>
  )
}

export default Model_3