import React from 'react'
import './Header.css';
import logo from '../../assets/images/logo.svg';
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className='containerHeader'>
      <div className='logo'>
      <Link to="/">
        <img src={logo} alt='' />
        </Link>
      </div>
      <div className='menu'>
         <ul>
          <li><Link to="/Model_S">Model S</Link></li>
          <li><Link to="/Model_Y">Model Y</Link></li> 
          <li><Link to="/Model_3">Model 3</Link></li>
          <li><Link to="/Model_X">Model X</Link></li>
         </ul>
      </div>
      <div className='rightMenu'>
         <ul>
         <li><Link to="/Shop">Shop</Link></li>
         <li><Link>Tesla Account</Link></li>
         </ul>
      </div>
    </div>
  )
}

export default Header

