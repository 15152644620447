import React from 'react'
import './Section.css';
import downArrow from '../../assets/images/down-arrow.svg';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';


function Section({title, description, leftButtonText, rightButtonText, backgroundImage}) {
  return (
    <Wrap bgImage={backgroundImage} >
      <Fade bottom>
      <div className="itemText">
          <h1> {title} </h1>
          <p> {description} </p>
      </div>
      </Fade>

      <div className="buttonGroup">
      <Fade bottom>
        <div className='buttoms'>
          <button className="LeftButton"> {leftButtonText} </button>
          <button className="LeftButton"> {rightButtonText} </button>
        </div>
        </Fade>
        <div className='downArrow'>
            <img src={downArrow} alt='' />
        </div>
      </div>
    </Wrap>
  )
}

export default Section

const Wrap = styled.div`
   width: 100vw;
   height: 100vh;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   background-image: url("/images/model-s.jpg");
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   background-image: ${props => `url("/images/${props.bgImage}")` };
`

