import React from 'react'
import './Home.css';
import Section from '../Section/Section';

function Home() {
  return (
    <div class="Container">
      <Section 
        title="Model S"
        description="Order Online For Touchless Delivery"
        backgroundImage="model-s.jpg"
        leftButtonText="Custom Order"
        rightButtonText="Existing Inventory"
      />
       <Section 
        title="Model Y"
        description="Order Online For Touchless Delivery"
        backgroundImage="model-y.jpg"
        leftButtonText="Custom Order"
        rightButtonText="Existing Inventory"
      />
      <Section 
        title="Model 3"
        description="Order Online For Touchless Delivery"
        backgroundImage="model-3.jpg"
        leftButtonText="Custom Order"
        rightButtonText="Existing Inventory"
      />
      <Section 
        title="Model X"
        description="Order Online For Touchless Delivery"
        backgroundImage="model-x.jpg"
        leftButtonText="Custom Order"
        rightButtonText="Existing Inventory"
      />
      <Section 
        title="Lowest Cost Solar Panel In America"
        description="Money-Back Guarantee"
        backgroundImage="solar-panel.jpg"
        leftButtonText="Order Now"
        rightButtonText="Learn More"
      />
      <Section 
        title="Solar For New Roofs"
        description="Solar Roof Costs Less Than A New Roof Plus Solar Panels"
        backgroundImage="solar-roof.jpg"
        leftButtonText="Order Now"
        rightButtonText="Learn More"
      />
    </div>
    
  )
}

export default Home