import image1 from '../../assets/images/audio.jpg';
import image2 from '../../assets/images/connected.jpg';
import image3 from '../../assets/images/game.jpg';
import image4 from '../../assets/images/new-interior.jpg';

export const dataProgram = [
  {
    image: "https://avtodream.org/uploads/posts/2020-10/1602591785_tesla-ponizil-stoimost-model-s.jpg",
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Model S',
    button : 'Learn More'
  },
  {
    image: "https://blog.florianuhlemann.de/wp-content/uploads/2016/07/tesla_model_s_facelift_new_front_fascia.jpg",
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Model S',
    button : 'Learn More'
  },
  {
    image: "https://10wallpaper.com/wallpaper/2560x1600/1510/2015_Brabus_Tesla_Model_S_HD_Wallpaper_2560x1600.jpg",
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Model S',
    button : 'Learn More'
  },
  {
    image: "https://bri-tech.com/blog/wp-content/uploads/2015/06/tesla.jpg",
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Model S',
    button : 'Learn More'
  }
];
