import image1 from '../../assets/images/audio.jpg';
import image2 from '../../assets/images/connected.jpg';
import image3 from '../../assets/images/game.jpg';
import image4 from '../../assets/images/new-interior.jpg';

export const shopProgram = [
  {
    image: image1,
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Feature Model S',
    button : 'Learn More'
  },
  {
    image: image2,
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Feature Model S',
    button : 'Learn More'
  },
  {
    image: image3,
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Feature Model S',
    button : 'Learn More'
  },
  {
    image: image4,
    detail:"The Tesla Model S is a battery-powered liftback car serving as the flagship model of Tesla, Inc. The Model S features a dual-motor, all-wheel drive layout, although earlier versions of the Model S featured a rear-motor and rear-wheel drive layout.",
    title: 'Feature Model S',
    button : 'Learn More'
  }
];