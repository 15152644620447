import React from 'react'
import './Shop.css';
import { shopProgram } from '../Data/ShopProgram';
import downArrow from '../../assets/images/down-arrow.svg';
import Fade from 'react-reveal/Fade';

function Shop() {
  return (
    <div className='Container'>
      <div className="shopHero">
        <Fade bottom>
          <div className="shopText">
            <h1> Tesla Shop </h1>
            <p> Order Online For Touchless Delivery </p>
          </div>
        </Fade>
        <div className="buttonGroup">
          <Fade bottom>
            <div className='buttoms'>
              <button className="shopButton"> Custom Order </button>
              <button className="shopButton"> Existing Inventory </button>
            </div>
          </Fade>
          <div className='downArrow'>
            <img src={downArrow} alt='' />
          </div>
        </div>
      </div>

      <section className='sectionBox'>
        <div className='containerCustom'>
          {shopProgram.map((shopProgram) => (
            <div className='carDetailBox'>
            <Fade left>
              <div className='carDetail'>
                <h2><span>{shopProgram.title}</span></h2>
                <p><span>{shopProgram.detail}</span></p>
                <button className="learnButton">  {shopProgram.button} </button>
              </div>
              </Fade>
              <Fade right>
              <div className='carImage'>
                <img src={shopProgram.image} alt='' />
              </div>
              </Fade>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default Shop