import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Model_S from './components/Model_S/Model_S';
import Model_Y from './components/Model_Y/Model_Y';
import Model_3 from './components/Model_3/Model_3';
import Model_X from './components/Model_X/Model_X';
import Shop from './components/Shop/Shop';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


function App() {
  return (
    <Router>
    <div className="App">
      <Header />
          <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/Model_S">
                <Model_S />
              </Route>
              <Route path="/Model_Y">
                <Model_Y />
              </Route>
              <Route path="/Model_3">
                <Model_3 />
              </Route>
              <Route path="/Model_X">
                <Model_X />
              </Route>
              <Route path="/Shop">
                <Shop />
              </Route>
            </Switch>
    </div>
    </Router>

  );
}

export default App;












